import { yupResolver } from '@hookform/resolvers/yup'
import { t } from '@lingui/macro'
import { Box, FormControl, MenuItem, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import { differenceInYears, format, isValid as isDateValid, parse } from 'date-fns'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Logo } from '../../../components/PageStyles'
import { PageWrapper } from '../../../components/PageWrapper'
import OM1Logo from '../../../shared/assets/OM1_logo_1953x934.png'
import { surveyActions } from '../../../state/survey-actions'
import { Sex, SurveyState } from '../../../state/survey-reducer'

export type PatientInfoFormValues = {
    firstName: string
    lastName: string
    dob: string
    sex: Sex
    mrn: string
    phone: string
}

type PatientInfoPageProps = FrameworkComponentProps<SurveyState, typeof surveyActions, {}>

export const PatientInfoPage: React.FC<PatientInfoPageProps> = ({ state, actions }) => {
    const { patientInfo } = state
    const { setStateValue } = actions

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(t`First name is required`),
        lastName: Yup.string().required(t`Last name is required`),
        dob: Yup.string().required(t`Date of birth is required`),
        sex: Yup.string().required(t`Sex is required`),
        mrn: Yup.string().required(t`Medical record number is required`),
        phone: Yup.string()
            .required(t`Phone number is required`)
            .matches(/^(?:\+\d{1,3})?\d{10}$/, t`Phone number must be 10 digits, country code optional`)
    })

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
        control
    } = useForm<PatientInfoFormValues>({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
        defaultValues: {
            ...patientInfo
        }
    })

    const handleFormSubmit = () => {
        handleSubmit((data: PatientInfoFormValues) => {
            Object.entries(data).forEach(([key, value]) => {
                setStateValue('patientInfo', key, value)
            })
            setStateValue('patientInfo', 'age', differenceInYears(new Date(), new Date(data.dob)))
        })()
    }

    const handleDateChange = (onChange: (e: any) => void, e: Date | null) => {
        if (e && isDateValid(e)) {
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            onChange(format(e, 'MM/dd/yyyy'))
        }
    }

    return (
        <PageWrapper
            title={t`Patient Information`}
            helperText={t`Please enter the patient's information below.`}
            pageType='patient-info'
            imageElement={<Logo src={OM1Logo} alt={t`OM1 Logo`} />}
            handleSubmit={handleFormSubmit}
            canGoToNextStep={isValid}
        >
            <Box maxWidth={'400px'} margin={'auto'}>
                {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                <FormControl style={{ display: 'grid', gridTemplateColumns: '1fr', alignItems: 'center', gap: 24, justifyContent: 'center' }}>
                    <Box display={'flex'} gap={2}>
                        <Controller
                            control={control}
                            name='firstName'
                            render={({ field }) => (
                                <TextField
                                    label={t`First Name`}
                                    autoComplete='off'
                                    {...register('firstName')}
                                    {...field}
                                    error={!!errors.firstName}
                                    helperText={errors.firstName?.message}
                                    fullWidth
                                    data-testid='first-name-input'
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name='lastName'
                            render={({ field }) => (
                                <TextField
                                    label={t`Last Name`}
                                    autoComplete='off'
                                    {...register('lastName')}
                                    {...field}
                                    error={!!errors.lastName}
                                    helperText={errors.lastName?.message}
                                    fullWidth
                                    data-testid='last-name-input'
                                />
                            )}
                        />
                    </Box>
                    <Controller
                        control={control}
                        name='mrn'
                        render={({ field }) => (
                            <TextField
                                label={t`MRN`}
                                autoComplete='off'
                                {...register('mrn')}
                                {...field}
                                error={!!errors.mrn}
                                helperText={errors.mrn?.message}
                                fullWidth
                                data-testid='mrn-input'
                            />
                        )}
                    />

                    {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                    <Box display={'grid'} gridTemplateColumns={'2fr 1fr'} gap={2}>
                        <Controller
                            control={control}
                            name='dob'
                            render={({ field: { value, onChange, ...fieldParams } }) => (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        sx={{ marginRight: 1, flex: 1 }}
                                        label={t`Date of Birth`}
                                        {...register('dob')}
                                        {...fieldParams}
                                        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                                        value={value ? parse(value, 'MM/dd/yyyy', new Date()) : null}
                                        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                                        onChange={(e) => handleDateChange(onChange, e)}
                                        disableFuture={true}
                                        views={['year', 'month', 'day']}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                helperText: errors.dob?.message
                                            }
                                        }}
                                        data-testid='dob-input'
                                    />
                                </LocalizationProvider>
                            )}
                        />

                        <Controller
                            control={control}
                            name='dob'
                            render={({ field: { value, ...fieldParams } }) => (
                                <TextField
                                    label={t`Age`}
                                    value={differenceInYears(new Date(), new Date(value || new Date()))}
                                    disabled
                                    data-testid='age-input'
                                    {...fieldParams}
                                />
                            )}
                        />
                    </Box>
                    <Controller
                        control={control}
                        name='phone'
                        render={({ field }) => (
                            <TextField
                                type='tel'
                                label={t`Phone Number`}
                                autoComplete='off'
                                {...register('phone')}
                                {...field}
                                error={!!errors.phone}
                                helperText={errors.phone?.message}
                                fullWidth
                                data-testid='phone-input'
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name='sex'
                        render={({ field }) => (
                            <TextField
                                select
                                label={t`Sex`}
                                {...register('sex')}
                                {...field}
                                error={!!errors.sex}
                                fullWidth
                                role='select'
                                data-testid='sex-input'
                            >
                                <MenuItem value={Sex.Male}>{t`Male`}</MenuItem>
                                <MenuItem value={Sex.Female} data-testid='female-option'>{t`Female`}</MenuItem>
                            </TextField>
                        )}
                    />
                </FormControl>
            </Box>
        </PageWrapper>
    )
}

export function createPatientInfoPage<TState extends { survey: SurveyState }>() {
    return connectRedux(
        PatientInfoPage,
        (state: TState) => ({
            ...state.survey
        }),
        { ...surveyActions }
    )
}
