import { Box, LinearProgress, linearProgressClasses, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import { useWizard } from 'react-use-wizard'

export const ProgressBar: React.FC = () => {
    const { activeStep, stepCount } = useWizard()
    const theme = useTheme()
    return (
        <Box width={'100%'} mb={2}>
            <LinearProgress
                variant='determinate'
                value={(activeStep / (stepCount - 1)) * 100}
                sx={{
                    backgroundColor: grey[200],
                    borderRadius: '8px',
                    height: 10,
                    [`& .${linearProgressClasses.bar}`]: { color: theme.palette.secondary.main }
                }}
            />
        </Box>
    )
}
