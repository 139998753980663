import { addWeeks, format } from 'date-fns'

export function setRefreshTokenCookie(token: string) {
    // Set the expiration time to two weeks from now
    const expirationTime = addWeeks(new Date(), 2)

    // Format the expiration date
    const expires = format(expirationTime, "EEE, dd MMM yyyy HH:mm:ss 'GMT'")

    document.cookie = `refresh_token=${token}; expires=${expires}; path=/; Secure; SameSite=Strict`
}
