import { t } from '@lingui/macro'
import { Box, Typography, Slider } from '@mui/material'
import React from 'react'

interface SliderProps {
    left: string
    right: string
    onChange: (value: number) => void
    value: number
    disabled?: boolean
}

const PreferencesSlider: React.FC<SliderProps> = ({ left, right, onChange, value, disabled }) => {
    const [sliderValue, setSliderValue] = React.useState<number>(value)

    return (
        <Box
            sx={{
                padding: '8px'
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    alignItems: 'center',
                    margin: '8px 0'
                }}
            >
                <Typography variant='h6'>{left}</Typography>
                <Box />
                <Typography variant='h6' textAlign={'right'}>
                    {right}
                </Typography>
            </Box>
            <Slider
                data-testid='slider'
                step={1}
                disabled={disabled}
                value={sliderValue}
                min={0}
                max={10}
                track={false}
                marks={[
                    { label: '5', value: 0 },
                    { label: '4', value: 1 },
                    { label: '3', value: 2 },
                    { label: '2', value: 3 },
                    { label: '1', value: 4 },
                    { label: '0', value: 5 },
                    { label: '1', value: 6 },
                    { label: '2', value: 7 },
                    { label: '3', value: 8 },
                    { label: '4', value: 9 },
                    { label: '5', value: 10 }
                ]}
                onChange={(e, value) => setSliderValue(value as number)}
                onChangeCommitted={(e, value) => onChange(value as number)}
            />
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                <Typography fontStyle={'italic'} textAlign={'left'}>{t`More important`}</Typography>
                <Typography fontStyle={'italic'} textAlign={'center'}>{t`Equally important`}</Typography>
                <Typography fontStyle={'italic'} textAlign={'right'}>{t`More important`}</Typography>
            </Box>
        </Box>
    )
}

export default PreferencesSlider
