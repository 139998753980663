import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './JointInsights'

// Add inline styles to prevent over-scrolling
document.documentElement.style.overscrollBehavior = 'none'
document.body.style.margin = '0'
document.body.style.height = '100%'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)
