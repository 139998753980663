import { t } from '@lingui/macro'
import { Box, Typography, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import { EducationPageWrapper } from './EducationPageWrapper'
import { educationHeaderStyle } from './EducationStyles'

export const EducationPage6: React.FC = () => {
    const theme = useTheme()

    const gradientBoxStyle = {
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        background: `linear-gradient(to right, ${grey[800]}, ${theme.palette.secondary.main})`,
        p: 1,
        textAlign: 'center',
        position: 'relative',
        mb: 2
    }

    const carrotStyle = (color: string, left: string) => ({
        position: 'absolute',
        width: 0,
        height: 0,
        borderLeft: `10px solid transparent`,
        borderRight: `10px solid transparent`,
        borderTop: `10px solid ${color}`,
        top: 0,
        left,
        transform: 'translateX(-50%)'
    })

    const greyCarrotStyle = { '::before': carrotStyle(grey[200], '25%') }
    const blueCarrotStyle = { '::after': carrotStyle('#def4ff', '75%') }

    const compactListStyle = {
        marginTop: '4px',
        padding: 0
    }

    const sectionLabelStyle = {
        fontWeight: 'bold',
        color: theme.palette.secondary.main,
        marginBottom: 1,
        marginTop: 2,
        backgroundColor: grey[200],
        borderRadius: 2,
        padding: '0.5em',
        display: { xs: 'block', sm: 'none' } // Only show on small screens
    }

    const nonSurgicalSectionLabelStyle = { ...sectionLabelStyle, color: 'black' }

    const ContentBox: React.FC<{ isNonSurgical?: boolean; children: React.ReactNode }> = ({ isNonSurgical = true, children }) => (
        <Box mx={2} display={'flex'} flexDirection={'column'} gap={1} color={isNonSurgical ? 'inherit' : theme.palette.secondary.dark}>
            <Typography sx={isNonSurgical ? nonSurgicalSectionLabelStyle : sectionLabelStyle}>
                {isNonSurgical ? t`Non-Surgical` : t`Surgical`}
            </Typography>
            {children}
        </Box>
    )

    const SectionHeader: React.FC<{ title: string }> = ({ title }) => (
        <Box sx={Object.assign({}, gradientBoxStyle, blueCarrotStyle, greyCarrotStyle)}>
            <Typography variant='h5' fontWeight={'bold'} color={'white'}>
                {title}
            </Typography>
        </Box>
    )

    const ComparisonSection: React.FC<{ title: string; nonSurgical: React.ReactNode; surgical: React.ReactNode }> = ({
        title,
        nonSurgical,
        surgical
    }) => (
        <>
            <SectionHeader title={title} />
            {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
            <Box display={'grid'} gridTemplateColumns={{ xs: '1fr', sm: '1fr auto 1fr' }} my={2}>
                <ContentBox isNonSurgical>{nonSurgical}</ContentBox>
                <Box width={2} sx={{ backgroundColor: grey[300] }} />
                <ContentBox isNonSurgical={false}>{surgical}</ContentBox>
            </Box>
        </>
    )

    return (
        <EducationPageWrapper>
            <Box sx={educationHeaderStyle} m={4}>
                <Typography variant='h4'>{t`Compare Your Treatment Options`}</Typography>
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 0.5,
                    textAlign: 'center',
                    alignItems: 'center'
                }}
            >
                <Typography variant='h5' fontWeight={'bold'} sx={{ backgroundColor: grey[200] }} p={1.5}>{t`Non-Surgical`}</Typography>
                <Typography variant='h5' fontWeight={'bold'} color='secondary' p={1.5} sx={{ backgroundColor: '#def4ff' }}>{t`Surgical`}</Typography>
            </Box>

            <ComparisonSection
                title={t`What is Involved?`}
                nonSurgical={
                    <>
                        <Typography>
                            <u>{t`Exercise and lifecycle changes`}</u>
                            {': '}
                            {t`Finding ways to get comfortable and stay active. Could involve losing weight, exercising, using a walking aid, or learning to keep active in spite of the pain.`}
                        </Typography>
                        <Typography>
                            <u>{t`Medicine`}</u>
                            {': '}
                            {t`Taking over-the-counter or prescription medicine to relieve pain.`}
                        </Typography>
                        <Typography>
                            <u>{t`Injections`}</u>
                            {': '}
                            {t`A health care provider injects your knee with joint medicine.`}
                        </Typography>
                    </>
                }
                surgical={
                    <Typography>
                        {t`During an operation, the surgeon replaces the arthritic knee joint with a knee replacement. You may go home the same day or stay in hospital for a few days. You can use a walking aid if needed for 2 to 4 weeks and will continue to improve for 12 months and more.`}
                    </Typography>
                }
            />

            <ComparisonSection
                title={t`Result`}
                nonSurgical={
                    <Typography>{t`People with knee arthritis exercising regularly and focusing on healther habits will generally have less pain and be able to do more. People who learn and practice ways to adapt to living with arthritis will tend to experience less pain.`}</Typography>
                }
                surgical={
                    <>
                        <Typography>
                            {t`Refer to your`}{' '}
                            <b>
                                <em>{t`Personalized Benefit & Risk Report`}</em>
                            </b>{' '}
                            {t`to see the likelihood of getting better with surgery.`}
                        </Typography>
                        <Typography>
                            {t`On average,`} <b>{t`80 to 90 out of 100`}</b> {t`people are happy with their knee replacement. Around`}{' '}
                            <b>{t`35 in 100`}</b> {t`people do not feel like their knees are normal.`}
                        </Typography>
                    </>
                }
            />

            <ComparisonSection
                title={t`What are the risks?`}
                nonSurgical={
                    <>
                        <Typography>{t`People with knee arthritis exercising regularly and focusing on healther habits will generally have less pain and be able to do more. People who learn and practice ways to adapt to living with arthritis will tend to experience less pain.`}</Typography>
                        <Typography>
                            {t`About`} <b>{t`1 or 2 out of 100`}</b>{' '}
                            {t`people who use anti inflammatory medications like ibuprofen experience a serious condition like stomach problems, bleeding, heart attack, or kidney problems.`}
                        </Typography>
                        <Typography>{t`The risks of these side effects increases with age and how long medications are used.`}</Typography>
                        <Typography mt={2}>
                            <u>
                                <i>{t`Injections`}</i>
                            </u>
                        </Typography>
                        <ul style={{ margin: '0 8px', padding: 0 }}>
                            <li style={compactListStyle}>
                                <Typography>
                                    {t`Injections can result in minor problems including discomfort or swelling in about`} <b>{t`3 out of 100`}</b>{' '}
                                    {t`people.`}
                                </Typography>
                            </li>
                            <li style={compactListStyle}>
                                <Typography>
                                    {t`They can also result in more serious problems such as infection of the knee or an allergic reaction in about`}{' '}
                                    <b>{t`1 out of 100`}</b> {t`people.`}
                                </Typography>
                            </li>
                        </ul>
                    </>
                }
                surgical={
                    <>
                        <Typography>
                            {t`Refer to the`}{' '}
                            <b>
                                <em>{t`Personalized Benefit & Risk Report`}</em>
                            </b>{' '}
                            {t`for your personalized likelihood of getting better with surgery. On average:`}
                        </Typography>
                        <ul style={{ padding: '0', margin: '4px 8px' }}>
                            <li style={compactListStyle}>
                                <Typography>
                                    {t`Infection in the knee can occur after a knee replacement in`} <b>{t`1 out of 100`}</b> {t`people`}
                                </Typography>
                            </li>
                            <li style={compactListStyle}>
                                <Typography>
                                    {t`Stiffness or loss of motion can occur in around`} <b>{t`4 out of 100`}</b> {t`people`}
                                </Typography>
                            </li>
                            <li style={compactListStyle}>
                                <Typography>
                                    {t`Knee replacements can loosen and cause pain in around`} <b>{t`15 out of 100`}</b>{' '}
                                    {t`people within 20 years of having the operation. This may require further surgery.`}
                                </Typography>
                            </li>
                            <li style={compactListStyle}>
                                <Typography>
                                    {t`Temporary or permanent damage to a nerve or major blood vessel can occur in around`} <b>{t`1 out of 1000`}</b>{' '}
                                    {t`people during surgery.`}
                                </Typography>
                            </li>
                            <li style={compactListStyle}>
                                <Typography>
                                    {t`A blood clot can occur with three months after surgery in`} <b>{t`2 out of 100`}</b> {t`people.`}
                                </Typography>
                            </li>
                            <li style={compactListStyle}>
                                <Typography>
                                    {t`A serious or potentially life threatening medical problem such as a heart attack, blood clot that travels to the lungs disturbing breathing, a stroke, or kidney failure can occur in`}{' '}
                                    <b>{t`1 to 2 out of 100`}</b> {t`people in the initial weeks after surgery.`}
                                </Typography>
                            </li>
                            <li style={compactListStyle}>
                                <Typography>{t`These risks are greater for people with heart or lung disease, as well as for people who smoke or are overweight.`}</Typography>
                            </li>
                        </ul>
                    </>
                }
            />

            <ComparisonSection
                title={t`How long does it take for treatement to take effect?`}
                nonSurgical={
                    <>
                        <Typography>{t`Many people feel less pain when the body is in better condition after several weeks or months of exercise.`}</Typography>
                        <Typography>{t`Medicines can relieve pain temporarily within an hour or so.`}</Typography>
                        <Typography>{t`If a steroid injection relieves pain, this is usually felt within a week of having it.`}</Typography>
                    </>
                }
                surgical={
                    <>
                        <Typography>{t`Most of the improvement in function and reduction of pain occur within the first 12 weeks, but people can continue to improve for a year after surgery.`}</Typography>
                        <Typography>{t`Most people walk without aids within 6 weeks of the operation.`}</Typography>
                    </>
                }
            />

            <ComparisonSection
                title={t`When does the doctor recomment this treatment?`}
                nonSurgical={
                    <Typography>{t`For most patients, it is important to try the simple non-surgical treatments first to see if we can delay or even avoid surgery. If simple treatments are not helping, we can try more advanced treatments like prescription medications or injections. Non-surgical treatment may also be recommended if you are not currently ready for surgery due to medical reasons.`}</Typography>
                }
                surgical={
                    <Typography>
                        {t`Refer to your`}{' '}
                        <b>
                            <i>{t`Personalized Benefit & Risk Report`}</i>
                        </b>{' '}
                        {t`to see the likelihood of getting better with surgery.`}
                    </Typography>
                }
            />
        </EducationPageWrapper>
    )
}
