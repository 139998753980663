import { debounce, fork } from 'redux-saga/effects'
import { JointInsightsApiActionTypes } from '../state'
import { createCheckDeviceRegistrationAuthSaga } from './check-device-registration-auth-saga'
import { createClearDeviceRegistrationAuthSaga } from './clear-device-registration-auth-saga'
import { createGetJointInsightsVersionSaga } from './get-joint-insights-version-saga'
import { createPollForTokenSaga } from './poll-for-token-saga'
import { createStartDeviceRegistrationAuthSaga } from './start-device-registration-auth-saga'

export function createJointInsightsApiSaga() {
    const getJointInsightsVersionSaga = createGetJointInsightsVersionSaga()
    const checkDeviceRegistrationAuthSaga = createCheckDeviceRegistrationAuthSaga()
    const startDeviceRegistrationAuthSaga = createStartDeviceRegistrationAuthSaga()
    const clearDeviceRegistrationAuthSaga = createClearDeviceRegistrationAuthSaga()
    const pollForTokenSaga = createPollForTokenSaga()

    return function* () {
        yield debounce(0, JointInsightsApiActionTypes.GET_JOINT_INSIGHTS_VERSION, getJointInsightsVersionSaga)
        yield debounce(0, JointInsightsApiActionTypes.CHECK_DEVICE_REGISTRATION_AUTH, checkDeviceRegistrationAuthSaga)
        yield debounce(0, JointInsightsApiActionTypes.START_DEVICE_REGISTRATION_AUTH, startDeviceRegistrationAuthSaga)
        yield debounce(0, JointInsightsApiActionTypes.CLEAR_DEVICE_REGISTRATION_AUTH, clearDeviceRegistrationAuthSaga)
        yield fork(pollForTokenSaga)
    }
}
