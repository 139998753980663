import { DeviceRegistrationAuthStatusType, jointInsightsApiActions, JointInsightsApiState, OpenAPI } from '@om1/joint-insights-api'
import { Auth0State } from '@om1/platform-authentication'
import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import React, { useCallback, useEffect } from 'react'
import { config } from '../Config'
import { createPollingPageComponent, SubmitAuthFormComponent } from './AuthPages'
import { createSurveyComponent } from './HomePage'

const PollingPage = createPollingPageComponent()
const Survey = createSurveyComponent()

type AuthenticationHandlerProps = FrameworkComponentProps<
    { authStatus: DeviceRegistrationAuthStatusType; auth0: Auth0State },
    typeof jointInsightsApiActions,
    {}
>

const AuthenticationHandlerComponent: React.FC<AuthenticationHandlerProps> = ({ state, actions }) => {
    const { authStatus } = state

    const onSubmitAuthForm = useCallback(
        (email: string) => {
            actions.startDeviceRegistrationAuth(email, config.auth0ClientId)
        },
        [actions]
    )

    useEffect(() => {
        if (authStatus === 'LOADING') {
            actions.checkDeviceRegistrationAuth(config.auth0ClientId)
        }
    }, [actions, authStatus])

    if (authStatus === 'AUTHENTICATED') {
        OpenAPI.TOKEN = state.auth0.accessToken
        return <Survey />
    }

    if (authStatus === 'UNAUTHENTICATED') {
        return <SubmitAuthFormComponent onSave={onSubmitAuthForm} />
    }

    if (authStatus === 'LOADING') {
        return <></>
    }

    return <PollingPage />
}

export function createAuthenticationHandlerComponent<TState extends { jointInsightsApi: JointInsightsApiState; auth0: Auth0State }>() {
    return connectRedux(
        AuthenticationHandlerComponent,
        (state: TState) => ({
            authStatus: state.jointInsightsApi.authStatus,
            auth0: state.auth0
        }),
        { ...jointInsightsApiActions }
    )
}

export type AuthenticationHandler = ReturnType<typeof createAuthenticationHandlerComponent>
