import { t } from '@lingui/macro'
import { Box, Typography } from '@mui/material'
import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import React from 'react'
import { SurveyState } from '../state/survey-reducer'

type PatientInfoHeaderProps = FrameworkComponentProps<SurveyState, {}, { style?: React.CSSProperties }>

export const PatientInfoHeaderComponent: React.FC<PatientInfoHeaderProps> = ({ state, props }) => {
    const { patientInfo } = state
    const { style } = props

    return (
        <Box textAlign={'right'} style={style}>
            <Typography>
                {patientInfo.lastName}, {patientInfo.firstName}
            </Typography>
            <Typography>
                {t`MRN:`} {patientInfo.mrn}
            </Typography>
        </Box>
    )
}

export function createPatientInfoHeaderComponent<TState extends { survey: SurveyState }>() {
    return connectRedux(PatientInfoHeaderComponent, (state: TState) => ({
        ...state.survey
    }))
}
