import { ActionsUnion, createAction } from '@om1/platform-utils'
import { DeviceRegistrationCodeDTO } from '../codegen/client'
import { DeviceCodeResponse, DeviceRegistrationAuthStatusType, PollingStatusType } from './joint-insights-api-state'

export enum JointInsightsApiActionTypes {
    GET_JOINT_INSIGHTS_VERSION = '@@jointInsightsApi/getJointInsightsVersion',
    SET_JOINT_INSIGHTS_VERSION = '@@jointInsightsApi/setJointInsightsVersion',
    START_DEVICE_REGISTRATION_AUTH = '@@jointInsightsApi/startDeviceRegistrationAuth',
    SET_DEVICE_REGISTRATION_AUTH = '@@jointInsightsApi/setDeviceRegistrationAuth',
    SET_DEVICE_REGISTRATION_AUTH_STATUS = '@@jointInsightsApi/setDeviceRegistrationAuthStatus',
    SET_POLL_FOR_TOKEN = '@@jointInsightsApi/setPollForToken',
    CHECK_DEVICE_REGISTRATION_AUTH = '@@jointInsightsApi/checkDeviceRegistrationAuth',
    CLEAR_DEVICE_REGISTRATION_AUTH = '@@jointInsightsApi/clearDeviceRegistrationAuth',
    START_AUTH_TOKEN_POLLING = '@@jointInsightsApi/startAuthTokenPolling',
    STOP_AUTH_TOKEN_POLLING = '@@jointInsightsApi/stopAuthTokenPolling',
    SET_POLLING_STATUS = '@@jointInsightsApi/setPollingStatus'
}

export const jointInsightsApiActions = {
    getJointInsightsVersion: () => createAction(JointInsightsApiActionTypes.GET_JOINT_INSIGHTS_VERSION),
    setJointInsightsVersion: (version: string, alembicVersion: string, analyticsDatabase: string) =>
        createAction(JointInsightsApiActionTypes.SET_JOINT_INSIGHTS_VERSION, {
            version: version,
            alembicVersion: alembicVersion,
            analyticsDatabase: analyticsDatabase
        }),
    startDeviceRegistrationAuth: (email: string, clientId: string) =>
        createAction(JointInsightsApiActionTypes.START_DEVICE_REGISTRATION_AUTH, { email, clientId }),
    setDeviceRegistrationAuth: (deviceFlowCodeResponse: DeviceRegistrationCodeDTO, expires: string, email: string) =>
        createAction(JointInsightsApiActionTypes.SET_DEVICE_REGISTRATION_AUTH, { deviceFlowCodeResponse, expires, email }),
    setDeviceRegistrationAuthStatus: (status: DeviceRegistrationAuthStatusType) =>
        createAction(JointInsightsApiActionTypes.SET_DEVICE_REGISTRATION_AUTH_STATUS, status),
    setPollForToken: (data: { access_token: string; refresh_token: string; expires_in: number; token_type: string }) =>
        createAction(JointInsightsApiActionTypes.SET_POLL_FOR_TOKEN, data),
    checkDeviceRegistrationAuth: (clientId: string) => createAction(JointInsightsApiActionTypes.CHECK_DEVICE_REGISTRATION_AUTH, { clientId }),
    clearDeviceRegistrationAuth: () => createAction(JointInsightsApiActionTypes.CLEAR_DEVICE_REGISTRATION_AUTH),
    startAuthTokenPolling: (data: { deviceFlowCodeResponse: DeviceCodeResponse; clientId: string; expirationTime?: number }) =>
        createAction(JointInsightsApiActionTypes.START_AUTH_TOKEN_POLLING, data),
    stopAuthTokenPolling: () => createAction(JointInsightsApiActionTypes.STOP_AUTH_TOKEN_POLLING),
    setPollingStatus: (status: PollingStatusType) => createAction(JointInsightsApiActionTypes.SET_POLLING_STATUS, status)
}

export type JointInsightsApiActions = ActionsUnion<typeof jointInsightsApiActions>
