import { t } from '@lingui/macro'
import { Box, Button, Typography } from '@mui/material'
import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import React from 'react'
import { useWizard } from 'react-use-wizard'
import { headerStyle, pageStyle } from '../../../components/PageStyles'
import { createPatientInfoHeaderComponent } from '../../../components/PatientInfoHeader'
import { surveyActions } from '../../../state/survey-actions'
import { createPreferencesPage } from './PreferencesPage'
import { createQuizPage } from './QuizPage'
import { createReviewPage } from './ReviewPage'

const QuizComponent = createQuizPage()
const PreferencesComponent = createPreferencesPage()
const ReviewComponent = createReviewPage()

type ResultsPageProps = FrameworkComponentProps<{}, typeof surveyActions, {}>

export const ResultsPage: React.FC<ResultsPageProps> = ({ actions }) => {
    const { resetSurvey } = actions
    const { goToStep } = useWizard()

    const PatientInfoHeader = createPatientInfoHeaderComponent()

    const resetWizard = () => {
        resetSurvey()
        goToStep(0)
    }
    return (
        <Box sx={pageStyle}>
            {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
            <Box sx={headerStyle} display='grid' gridTemplateColumns='3fr 1fr auto' alignItems='center' gap={2}>
                <Typography variant='h5' textAlign={'center'}>
                    {t`Thank you for completing all the survey questions!`}
                </Typography>
                <Button variant='contained' color='primary' onClick={() => resetWizard()}>{t`Reset`}</Button>
                <PatientInfoHeader />
            </Box>
            <Box mx={4} my={2}>
                <Typography variant='h5'>{t`Quiz Results`}</Typography>
                {[0, 1, 2, 3, 4, 5].map((index) => (
                    <Box key={`quiz-${index}`} mx={4} my={2}>
                        <Typography variant='h6'>{t`Question ${index + 1}`}</Typography>
                        <QuizComponent index={index} editable={false} />
                    </Box>
                ))}

                <Typography variant='h5'>{t`Preferences`}</Typography>
                <Box mx={4} my={2}>
                    {[0, 1, 2].map((index) => (
                        <PreferencesComponent key={`preference-${index}`} editable={false} index={index} />
                    ))}
                </Box>

                <Typography variant='h5'>{t`Review`}</Typography>
                {[0, 1, 2].map((index) => (
                    <Box key={`review-${index}`} mx={4} my={2}>
                        <ReviewComponent editable={false} index={index} />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export function createResultsPage<TState extends {}>() {
    return connectRedux(ResultsPage, (state: TState) => ({}), { ...surveyActions })
}
