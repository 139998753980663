import { jointInsightsApiConfig, JointInsightsApiConfigInterface } from '@om1/joint-insights-api'
import { auth0Config, Auth0ConfigInterface } from '@om1/platform-authentication'
import { platformConfig, PlatformConfigInterface } from '@om1/platform-config/platform-config'
import { trackingConfig, TrackingConfigInterface } from '@om1/platform-tracking'
import React, { PropsWithChildren } from 'react'

// any provider or package needing environment variables get initialized here
export interface ConfigInterface extends PlatformConfigInterface, TrackingConfigInterface, JointInsightsApiConfigInterface, Auth0ConfigInterface {}

export const UACConfigContext = React.createContext<Partial<ConfigInterface>>({})

export const Config: React.FunctionComponent<PropsWithChildren<{ config: ConfigInterface }>> = ({ children, config }) => {
    const validatedConfig = {
        ...config
    }
    return <UACConfigContext.Provider value={validatedConfig}>{children}</UACConfigContext.Provider>
}

export const config: ConfigInterface = { ...platformConfig, ...trackingConfig, ...jointInsightsApiConfig, ...auth0Config }
