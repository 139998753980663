import { t } from '@lingui/macro'
import { Typography } from '@mui/material'
import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import React from 'react'
import { useWizard } from 'react-use-wizard'
import { PageWrapper } from '../../../components/PageWrapper'
import Quiz from '../../../components/Quiz'
import { surveyActions } from '../../../state/survey-actions'
import { SurveyState } from '../../../state/survey-reducer'

type QuizPageProps = FrameworkComponentProps<{ quizAnswers: string[] }, typeof surveyActions, { index: number; editable?: boolean }>

export const QuizPage: React.FC<QuizPageProps> = ({ state, actions, props }) => {
    const { index, editable } = props
    const { setStateValue } = actions
    const { quizAnswers } = state
    const { isLastStep } = useWizard()

    const setQuizAnswer = (index: number, value: string) => setStateValue('quizAnswer', index, value)

    const quizConfig = [
        {
            questionIndex: 0,
            question: t`Knee arthritis occurs with time as you lose the cartilage covering your joints. This causes pain in the joints only at night time.`,
            correctAnswer: t`False`,
            correctAnswerExplanation: t`Knee arthritis does occur with time as you lose cartilage, but the pain from arthritis can occur at any time. Pain from arthritis occurs especially after activity and sometimes at night when it is severe.`
        },
        {
            questionIndex: 1,
            question: t`Taking simple pain medications, using a walking aid, losing weight, and making changes to live a healthier life can help reduce your knee pain.`,
            correctAnswer: t`True`,
            correctAnswerExplanation: t`Taking simple pain medications, using a walking aid, losing weight, and making changes to live a healthier life can help reduce your knee pain.`
        },
        {
            questionIndex: 2,
            question: t`Injections of steroid or injections of hyaluronic acid into your joints make the joint healther and are a cure of arthritis.`,
            correctAnswer: t`False`,
            correctAnswerExplanation: t`Injections of steroid or hyaluronic acid can offer some limited relief from pain, but injections do not make the joint healthier and are not a cure for arthritis.`
        },
        {
            questionIndex: 3,
            question: t`A knee replacement involves replacing the ends of the bones around the knee with metal and plastic components.`,
            correctAnswer: t`True`
        },
        {
            questionIndex: 4,
            question: t`A knee replacement has a 1 in 1000 chance of infection and 4 in1000 chance of stiffness and poor movement.`,
            correctAnswer: t`False`,
            correctAnswerExplanation: t`Knee replacement has a 1 in 100 chance of infection and a 4 in 100 chance of stiffness and poor movement.`
        },
        {
            questionIndex: 5,
            question: t`Serious or life-threatening problems such as a heart attack, blood clot to the lungs, and stroke can occur with knee replacement surgery.`,
            correctAnswer: t`True`
        }
    ]

    const quizItem = quizConfig[index]

    const QuizWrapper = () => (
        <Quiz
            answers={[t`True`, t`False`, t`I don't know`]}
            correctAnswer={quizItem.correctAnswer}
            correctAnswerExplanation={quizItem.correctAnswerExplanation}
            response={quizAnswers[index]}
            onChange={(value) => setQuizAnswer(index, value)}
            disabled={!editable}
        />
    )

    if (isLastStep) {
        return <QuizWrapper />
    }

    return (
        // eslint-disable-next-line string-to-lingui/text-restrictions
        <PageWrapper title={t`Test your knowledge`} pageType='quiz' canGoToNextStep={quizAnswers[quizItem.questionIndex] !== ''}>
            <Typography>{quizItem.question}</Typography>
            <QuizWrapper />
        </PageWrapper>
    )
}

export function createQuizPage<TState extends { survey: SurveyState }>() {
    return connectRedux(
        QuizPage,
        (state: TState) => ({
            quizAnswers: state.survey.quizAnswers
        }),
        { ...surveyActions }
    )
}
