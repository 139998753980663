import { grey } from '@mui/material/colors'
import { styled } from '@mui/system'

export const pageStyle = {
    overflow: 'scroll',
    minWidth: '100%',
    maxWidth: '800px', // Limit maximum width for larger screens
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    margin: '0 auto' // Center the content
}

export const headerStyle = {
    backgroundColor: grey[200],
    padding: '10px 16px',
    margin: '0 0 10px 0',
    borderRadius: '8px 8px 0px 0px',
    fontSize: { xs: '1.1rem', sm: '1.25rem' } // Responsive font size
}

export const bodyStyle = {
    margin: { xs: 2, sm: 3 } // Responsive margin
}

export const Logo = styled('img')({
    width: '64px',
    cursor: 'pointer',
    lineHeight: 0,
    marginRight: '12px',
    marginTop: '12px'
})
