/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceRegistrationCodeDTO } from '../models/DeviceRegistrationCodeDTO'
import type { DeviceRegistrationCodeRequestDTO } from '../models/DeviceRegistrationCodeRequestDTO'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class DefaultService {
    /**
     * Start Oauth
     * @returns DeviceRegistrationCodeDTO Successful Response
     * @throws ApiError
     */
    public static startOauthOauthDeviceCodePost({
        requestBody
    }: {
        requestBody: DeviceRegistrationCodeRequestDTO
    }): CancelablePromise<DeviceRegistrationCodeDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/joint-insights/oauth/device/code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
}
