import { auth0Actions, Auth0Service } from '@om1/platform-authentication'
import { call, put, select } from 'redux-saga/effects'
import { clearDeviceFlowCodeCookie, getCookie, setAccessTokenCookie, setRefreshTokenCookie } from '../cookies'
import { jointInsightsApiActions, JointInsightsApiState } from '../state'

export function createCheckDeviceRegistrationAuthSaga() {
    return function* (action: ReturnType<typeof jointInsightsApiActions.checkDeviceRegistrationAuth>) {
        const clientId = action.payload.clientId
        const authStatus = yield select((store: { jointInsightsApi: JointInsightsApiState }) => {
            return store.jointInsightsApi.authStatus
        })
        if (authStatus !== 'CHECKING_AUTH') {
            yield put(jointInsightsApiActions.setDeviceRegistrationAuthStatus('CHECKING_AUTH'))
            const access_token = getCookie('access_token')
            const refresh_token = getCookie('refresh_token')
            const device_flow_code_response_json = getCookie('device_flow_code_response')
            if (device_flow_code_response_json) {
                const device_flow_code_response = JSON.parse(device_flow_code_response_json)
                const expiresAt = new Date(device_flow_code_response['expiresAt']).toUTCString()
                yield put(jointInsightsApiActions.setDeviceRegistrationAuth(device_flow_code_response, expiresAt, device_flow_code_response['email']))
                yield put(jointInsightsApiActions.setDeviceRegistrationAuthStatus('POLLING'))
                yield put(jointInsightsApiActions.startAuthTokenPolling({ deviceFlowCodeResponse: device_flow_code_response, clientId }))
            } else {
                if (!access_token || isTokenExpired(access_token)) {
                    if (refresh_token) {
                        try {
                            const data = yield call(Auth0Service.exchangeToken, { refreshToken: refresh_token, clientId })
                            const new_access_token = data.access_token

                            if (new_access_token) {
                                setAccessTokenCookie(data.access_token, data.expires_in)
                                yield put(auth0Actions.setAccessToken(new_access_token))

                                if (data.refresh_token) {
                                    setRefreshTokenCookie(data.refresh_token)
                                    yield put(auth0Actions.setRefreshToken(data.refresh_token))
                                }
                                clearDeviceFlowCodeCookie()
                                yield put(jointInsightsApiActions.setDeviceRegistrationAuthStatus('AUTHENTICATED'))
                            } else {
                                yield put(jointInsightsApiActions.clearDeviceRegistrationAuth())
                                return
                            }
                        } catch (error) {
                            yield put(jointInsightsApiActions.clearDeviceRegistrationAuth())
                            return
                        }
                    } else {
                        yield put(jointInsightsApiActions.clearDeviceRegistrationAuth())
                        return
                    }
                } else {
                    yield put(auth0Actions.setAccessToken(access_token))
                    if (refresh_token) {
                        yield put(auth0Actions.setRefreshToken(refresh_token))
                    }
                    clearDeviceFlowCodeCookie()
                    yield put(jointInsightsApiActions.setDeviceRegistrationAuthStatus('AUTHENTICATED'))
                }
            }
        }
    }
}

export type CheckDeviceRegistrationAuthSaga = ReturnType<typeof createCheckDeviceRegistrationAuthSaga>

function isTokenExpired(token: string): boolean {
    try {
        const tokenParts = token?.split('.')

        if (tokenParts.length !== 3) {
            throw new Error('Invalid JWT token')
        }

        const payload = JSON.parse(atob(tokenParts[1]))

        if (!payload.exp) {
            throw new Error('Token does not have an expiration time')
        }

        const expirationTime = payload.exp * 1000
        return Date.now() > expirationTime
    } catch (error) {
        console.error('Error in isTokenExpired:', error)
        return true
    }
}
