import { auth0Actions } from '@om1/platform-authentication'
import { put } from 'redux-saga/effects'
import { clearAccessTokenCookie, clearDeviceFlowCodeCookie, clearRefreshTokenCookie } from '../cookies'
import { jointInsightsApiActions } from '../state'

export function createClearDeviceRegistrationAuthSaga() {
    return function* (action: ReturnType<typeof jointInsightsApiActions.clearDeviceRegistrationAuth>) {
        yield put(jointInsightsApiActions.stopAuthTokenPolling())
        clearAccessTokenCookie()
        clearRefreshTokenCookie()
        clearDeviceFlowCodeCookie()
        yield put(auth0Actions.setAccessToken(undefined))
        yield put(jointInsightsApiActions.setDeviceRegistrationAuthStatus('UNAUTHENTICATED'))
    }
}

export type ClearDeviceRegistrationAuthSaga = ReturnType<typeof createClearDeviceRegistrationAuthSaga>
