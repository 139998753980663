import { JointInsightsApiActions, JointInsightsApiActionTypes } from './joint-insights-api-actions'
import { JointInsightsApiState } from './joint-insights-api-state'

export const initialJointInsightsApiState: JointInsightsApiState = {
    version: '',
    alembicVersion: '',
    analyticsDatabase: '',
    authStatus: 'LOADING',
    pollingStatus: 'STOPPED'
}

export function jointInsightsApiReducer(previousState: JointInsightsApiState | undefined, action: JointInsightsApiActions): JointInsightsApiState {
    const state = previousState || initialJointInsightsApiState

    switch (action.type) {
        case JointInsightsApiActionTypes.SET_JOINT_INSIGHTS_VERSION:
            return {
                ...state,
                version: action.payload.version,
                alembicVersion: action.payload.alembicVersion,
                analyticsDatabase: action.payload.analyticsDatabase
            }
        case JointInsightsApiActionTypes.SET_DEVICE_REGISTRATION_AUTH_STATUS: {
            return {
                ...state,
                authStatus: action.payload
            }
        }
        case JointInsightsApiActionTypes.SET_DEVICE_REGISTRATION_AUTH: {
            return {
                ...state,
                deviceCodeResponse: {
                    ...action.payload.deviceFlowCodeResponse,
                    expiresAt: action.payload.expires,
                    email: action.payload.email
                }
            }
        }
        case JointInsightsApiActionTypes.SET_POLL_FOR_TOKEN: {
            return {
                ...state,
                pollForTokenResponse: { ...action.payload }
            }
        }
        case JointInsightsApiActionTypes.CLEAR_DEVICE_REGISTRATION_AUTH: {
            return {
                ...state,
                deviceCodeResponse: undefined,
                pollForTokenResponse: undefined,
                authStatus: 'UNAUTHENTICATED'
            }
        }
        case JointInsightsApiActionTypes.SET_POLLING_STATUS:
            return {
                ...state,
                pollingStatus: action.payload
            }
        default:
            return state
    }
}
