import { t } from '@lingui/macro'
import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import React from 'react'
import { useWizard } from 'react-use-wizard'
import { Logo } from '../../../components/PageStyles'
import { PageWrapper } from '../../../components/PageWrapper'
import Select from '../../../components/Select'
import OM1Logo from '../../../shared/assets/OM1_logo_1953x934.png'
import { languageActions } from '../../../shared/providers/internationalization/language-actions'
import { LocaleEnum } from '../../../shared/providers/internationalization/language-config'
import { LanguageState } from '../../../shared/providers/internationalization/language-state'

type LanguageSelectionPageProps = FrameworkComponentProps<LanguageState, typeof languageActions, {}>

export const LanguageSelectionPage: React.FC<LanguageSelectionPageProps> = ({ state, actions }) => {
    const { setLanguage } = actions
    const { locale } = state
    const { goToStep, activeStep } = useWizard()

    const handleOnChange = (value: string) => {
        if (value === t`English`) {
            setLanguage(LocaleEnum.English)
        } else {
            setLanguage(LocaleEnum.Spanish)
        }
        goToStep(activeStep)
    }

    return (
        // eslint-disable-next-line string-to-lingui/text-restrictions
        <PageWrapper title={t`Language Selection`} pageType='education' canGoToNextStep={`${locale}` !== ''}>
            <Logo src={OM1Logo} alt={t`OM1 Logo`} width={'64px'} />
            <Select response={locale === LocaleEnum.English ? t`English` : t`Español`} values={[t`English`, t`Español`]} onChange={handleOnChange} />
        </PageWrapper>
    )
}

export function createLanguageSelectionPage<TState extends { language: LanguageState }>() {
    return connectRedux(
        LanguageSelectionPage,
        (state: TState) => ({
            ...state.language
        }),
        { ...languageActions }
    )
}
