import { t } from '@lingui/macro'
import { Typography } from '@mui/material'
import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import React from 'react'
import { useWizard } from 'react-use-wizard'
import { PageWrapper } from '../../../components/PageWrapper'
import Select from '../../../components/Select'
import { surveyActions } from '../../../state/survey-actions'
import { SurveyState } from '../../../state/survey-reducer'

type ReviewPageProps = FrameworkComponentProps<
    { reviews: string[] },
    typeof surveyActions,
    { index: number; editable?: boolean; isLastUserStep?: boolean }
>

export const ReviewPage: React.FC<ReviewPageProps> = ({ state, actions, props }) => {
    const { setStateValue } = actions
    const { reviews } = state
    const { index, editable = true, isLastUserStep = false } = props
    const { isLastStep } = useWizard()

    const setReview = (index: number, value: string) => setStateValue('review', index, value)

    const reviewConfig = [
        {
            helperText: t`Please answer the following questions. If any answer is "No", talk to your physician.`,
            question: t`Do you know enough about the risks and benefits of surgery?`,
            title: t`Knowledge`
        },
        {
            question: t`Are you aware of what is most important to you?`,
            title: t`Preference`
        },
        {
            question: t`Do you feel that you can make the right decision?`,
            title: t`Support`
        }
    ]

    const config = reviewConfig[index]

    const ReviewContent = () => (
        <>
            <Typography>{config.question}</Typography>
            <Select disabled={!editable} response={reviews[index]} values={[t`Yes`, t`No`]} onChange={(value: string) => setReview(index, value)} />
        </>
    )

    if (isLastStep) {
        return <ReviewContent />
    }

    return (
        <PageWrapper
            title={t`Let's Review Your Decision`}
            pageType='review'
            helperText={config.helperText}
            isLastUserStep={isLastUserStep}
            // eslint-disable-next-line string-to-lingui/text-restrictions
            canGoToNextStep={reviews[index] !== ''}
        >
            <Typography fontWeight={'bold'}>{config.title}:</Typography>
            <ReviewContent />
        </PageWrapper>
    )
}

export function createReviewPage<TState extends { survey: SurveyState }>() {
    return connectRedux(
        ReviewPage,
        (state: TState) => ({
            reviews: state.survey.reviews
        }),
        { ...surveyActions }
    )
}
