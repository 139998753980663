import { t } from '@lingui/macro'
import { Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import { EducationPageWrapper } from './EducationPageWrapper'
import { educationHeaderStyle, listStyle } from './EducationStyles'

export const EducationPage3: React.FC = () => {
    return (
        <EducationPageWrapper>
            <Box sx={educationHeaderStyle} m={4}>
                <Typography variant='h4' color={grey[400]}>{t`Non Surgical Treatment:`}</Typography>
                <Typography variant='h4'>{t`Lifestyle Changes You can Make`}</Typography>
            </Box>
            <Box
                display={'grid'}
                // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                gridTemplateColumns={{ xs: '1fr', sm: '1fr 2fr' }}
                alignItems={'center'}
                mb={4}
                gap={4}
            >
                <Box display='flex' justifyContent='center'>
                    <img src='/exercise_186x118_2x.jpg' alt={t`Graphic of woman biking`} style={{ maxWidth: '100%', height: 'auto' }} />
                </Box>
                <Box>
                    <Typography variant='h5' fontWeight={'bold'} color='primary'>{t`Exercise, Weight Loss, & Improving Mindset`}</Typography>
                    <Typography>{t`Changes to your lifecycle including losing weight, being active, exercising, and improving your mood are all measures that can greatly improve the comfort of you knee as well as your general health.`}</Typography>
                </Box>
            </Box>
            <Box>
                <ul style={{ padding: '0' }}>
                    <li style={listStyle}>
                        <Typography>{t`A healthy body weight reduces the load on your knees and can help make things more comfortable. Losing weight can even help those with arthritis that are slightly overweight.`}</Typography>
                    </li>
                    <li style={listStyle}>
                        <Typography>{t`Doing activities like a brisk walk and leg strengthening exercises regularly can reduce pain. Exercising in a pool, swimming, or bicycling can also be very effective.`}</Typography>
                    </li>
                    <li style={listStyle}>
                        <Typography>{t`Feeling anxious, worries, or even a bit down can also make your problem feel worse. There are very effective ways to improve your mood that can make you more comfortable and active.`}</Typography>
                    </li>
                </ul>
            </Box>
            <Box
                display={'grid'}
                // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                gridTemplateColumns={{ xs: '1fr', sm: '1fr 2fr' }}
                alignItems={'center'}
                mt={4}
                gap={4}
            >
                <Box display='flex' justifyContent='center'>
                    <img
                        src='/walkingAids_188x127_2x.jpg'
                        alt={t`Graphic of people using walking aids`}
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                </Box>
                <Box>
                    <Typography variant='h5' fontWeight={'bold'} color='primary'>{t`Walking Aids`}</Typography>
                    <Typography>{t`Walking aids, such as a cane or walking stick, can help improve your mobility and independence while taking some of the pressure off of your knees. Different walking aids are available to fit your walking style and preferences.`}</Typography>
                </Box>
            </Box>
            <Box
                display={'grid'}
                // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                gridTemplateColumns={{ xs: '1fr', sm: '1fr 2fr' }}
                alignItems={'center'}
                mt={4}
                gap={4}
            >
                <Box display='flex' justifyContent='center'>
                    <img src='/meds_188x116_2x.jpg' alt={t`Prescription bottles graphic`} style={{ maxWidth: '100%', height: 'auto' }} />
                </Box>
                <Box>
                    <Typography variant='h5' fontWeight={'bold'} color='primary'>{t`Taking Medications`}</Typography>
                    <Typography>{t`Over-the counter pain relievers like Tylenol or anti-inflammatory medications like ibuprofen can reduce knee pain as well as feelings of stiffness and swelling for several hours. Prescription medications may be appropriate in some cases.`}</Typography>
                </Box>
            </Box>
        </EducationPageWrapper>
    )
}
