import { t } from '@lingui/macro'
import { Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import { EducationPageWrapper } from './EducationPageWrapper'
import { educationHeaderStyle, listStyle } from './EducationStyles'

export const EducationPage4: React.FC = () => {
    return (
        <EducationPageWrapper>
            <Box sx={educationHeaderStyle} m={4}>
                <Typography variant='h4' color={grey[400]}>{t`Non Surgical Treatment:`}</Typography>
                <Typography variant='h4'>{t`Joint Injections`}</Typography>
            </Box>
            <img
                src='/NonSurg_Header1_980x164_2x.png'
                alt={t`Graphic of joint injections using steroids & hyaluronic acid`}
                style={{ maxWidth: '100%', height: 'auto', margin: '16px 0' }}
            />
            <Box sx={{ my: 4 }}>
                <Typography variant='h5' fontWeight={'bold'} color='primary'>{t`Advantages`}</Typography>
                <Typography>{t`Joint injections may help relieve pain and symptoms for days to weeks, but they do not cure arthritis, 'lubricate' the joint, or make cartilage grow back. Injections may be given once or several times, at different visits.`}</Typography>
            </Box>
            <Box>
                <Typography variant='h5' fontWeight={'bold'} color='primary'>{t`Disadvantages`}</Typography>
                <ul style={{ padding: '0' }}>
                    <li style={listStyle}>
                        <Typography>{t`Injections into the knee can be uncomfortable and may have a small risk of developing infection.`}</Typography>
                    </li>
                    <li style={listStyle}>
                        <Typography>
                            <b>{t`3 of 100`}</b> {t`people have a minor problem such as discomfort or swelling following an injection.`}
                        </Typography>
                    </li>
                    <li style={listStyle}>
                        <Typography>
                            <b>{t`1 of 1000`}</b> {t`people have a major problem such as an infection in the knee or an allergic reaction.`}
                        </Typography>
                    </li>
                    <li style={listStyle}>
                        <Typography>{t`The local anesthetic used in steroid injections may cause damage to the cartilage in the knee.`}</Typography>
                    </li>
                </ul>
            </Box>
            <Box my={4}>
                <Typography variant='h5' fontWeight={'bold'} color='primary'>{t`What we know from studies`}</Typography>
                <ul style={{ padding: '0' }}>
                    <li style={listStyle}>
                        <Typography>{t`Results of scientific studies of steroid and hyaluronic injections vary, so it's not clear how well these injections work.`}</Typography>
                    </li>
                    <li style={listStyle}>
                        <Typography>{t`Slightly less than half the people getting these injections experience the benefit of less pain and being able to do more.`}</Typography>
                    </li>
                    <li style={listStyle}>
                        <Typography>{t`Some studies have suggested that steroid or hyaluronic acid injections may not relieve pain better than a fake (also called a placebo) injection.`}</Typography>
                    </li>
                </ul>
            </Box>
        </EducationPageWrapper>
    )
}
