import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import { Box, Button, CircularProgress, FormControl, FormHelperText, TextField, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { CenterBoxWrapper } from '@om1/platform-ui-kit/src/components/CenterBoxWrapper'
import React, { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import OM1Logo from '../../shared/assets/OM1_logo_1953x934.png'

const Logo = styled('img')({
    height: '70px',
    cursor: 'pointer',
    lineHeight: 0
})

type SubmitAuthFormValues = {
    email: string
}

type SubmitAuthFormComponentProps = {
    onSave: (email: string) => void
}

export const SubmitAuthFormComponent: React.FC<SubmitAuthFormComponentProps> = (props) => {
    const { onSave } = props

    const [isLoading, setIsLoading] = useState(false)

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(t`Invalid email format`)
            .required(t`Email is required`)
    })

    const {
        handleSubmit,
        register,
        formState: { errors },
        control
    } = useForm<SubmitAuthFormValues>({
        resolver: yupResolver(validationSchema)
    })

    const onSubmit: SubmitHandler<SubmitAuthFormValues> = (formValues: SubmitAuthFormValues) => {
        setIsLoading(true)
        onSave(formValues.email)
    }

    const renderHelperText = () => {
        if (errors.email?.message) {
            return (
                <FormHelperText error sx={{ position: 'relative', bottom: 0 }}>
                    {errors.email.message}
                </FormHelperText>
            )
        }
        return <></>
    }

    const renderSubmitButtonText = () => {
        if (isLoading) {
            return <CircularProgress color='inherit' size={24} />
        }
        return <Trans>Submit</Trans>
    }

    return (
        <CenterBoxWrapper>
            <Box
                sx={{
                    borderRadius: '8px',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '70px',
                    padding: '100px',
                    backgroundColor: 'white',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '40px',
                        alignItems: 'center',
                        justifyItems: 'center'
                    }}
                >
                    <Logo
                        src={OM1Logo}
                        onClick={() => {
                            window.open('https://www.om1.com/')
                        }}
                    />
                    <Typography variant='body2' sx={{ fontSize: '24pt' }}>
                        <Trans>Joint Insights</Trans>
                    </Typography>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '40px',
                            alignItems: 'center',
                            justifyItems: 'center',
                            minWidth: '300px'
                        }}
                    >
                        <FormControl fullWidth>
                            <Controller
                                control={control}
                                name='email'
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        id='email'
                                        label={t`Email`}
                                        {...register('email')}
                                        value={value}
                                        onChange={onChange}
                                        fullWidth
                                        required
                                    />
                                )}
                            />
                            {renderHelperText()}
                        </FormControl>
                        <Button variant='contained' type='submit' fullWidth={true} disabled={isLoading}>
                            {renderSubmitButtonText()}
                        </Button>
                    </Box>
                </form>
            </Box>
        </CenterBoxWrapper>
    )
}
