import { Box, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import { useWizard } from 'react-use-wizard'
import { ControlButtons } from './ControlButtons'
import { bodyStyle, headerStyle, pageStyle } from './PageStyles'
import { createPatientInfoHeaderComponent } from './PatientInfoHeader'
import { ProgressBar } from './ProgressBar'

const PatientInfoHeader = createPatientInfoHeaderComponent()

export type PageWrapperProps = {
    children: ReactNode
    title: string
    pageType: string
    helperText?: string
    imageElement?: ReactNode
    isLastUserStep?: boolean
    handleSubmit?: () => void
    canGoToNextStep?: boolean
}

export const PageWrapper: React.FC<PageWrapperProps> = ({ children, ...props }) => {
    const { isFirstStep } = useWizard()

    const { title, helperText, imageElement, isLastUserStep, handleSubmit, canGoToNextStep = true } = props
    return (
        <Box sx={{ height: '98dvh', overflow: 'auto', display: 'flex', flexDirection: 'column', width: '100%' }}>
            <ProgressBar />
            <Box sx={pageStyle}>
                <Box sx={headerStyle}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant='h6'>{title}</Typography>
                        {imageElement}
                        {!isFirstStep && <PatientInfoHeader />}
                    </Box>
                    {helperText && <Typography marginTop={2}>{helperText}</Typography>}
                </Box>
                <Box sx={bodyStyle}>{children}</Box>
            </Box>
            <ControlButtons isLastUserStep={isLastUserStep} handleSubmit={handleSubmit} canGoToNextStep={canGoToNextStep} />
        </Box>
    )
}
