import { t } from '@lingui/macro'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { EducationPageWrapper } from './EducationPageWrapper'
import { educationHeaderStyle } from './EducationStyles'

export const EducationPage1: React.FC = () => {
    return (
        <EducationPageWrapper>
            <Typography variant='h4' sx={educationHeaderStyle}>{t`Understand Your Condition`}</Typography>
            <Typography variant='h5' fontWeight={'bold'} color='primary' marginTop={3}>{t`The Knee Joint`}</Typography>
            <Typography>{t`The knee is the largest joint in the body. It is made up of the long bone in the upper leg or thigh (the femur), the large bone in the lower leg or shin (the tibia), and the kneecap (the patella). These three bones are covered with cartilage at the knee joint. Cartilage provides a smooth surface for these bones to move more easily against each other (see figure).`}</Typography>
            <Typography variant='h5' fontWeight={'bold'} color='primary' marginTop={3}>{t`Knee Arthritis`}</Typography>
            <Typography>{t`Your knees support your body weight. As time goes by with activity and age, we slowly lose cartilage (see figure). This is called arthritis. This condition can develop slowly and make joints like the knee start to hurt.`}</Typography>
            <Typography variant='h6' color='secondary' marginTop={3} fontStyle={'italic'}>{t`Symptoms`}</Typography>
            <Typography>{t`You may notice pain and stiffness related to your knee arthritis. These symptoms can occur at any time but often after activity and sometimes at night.`}</Typography>
            <Typography variant='h6' color='secondary' marginTop={3} fontStyle={'italic'}>{t`Diagnosis`}</Typography>
            <Typography>{t`Knee arthritis can be diagnosed based on listening to your experiences living with the condition and by examining your knee. X-rays are usually taken to make a diagnosis. It also helps the surgeon decide whether surgery is an option.`}</Typography>
            {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
            <Box display={'grid'} gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr' }} marginTop={4} textAlign={'center'} gap={6}>
                <Box>
                    <img src='/knee_detail_ENG1_400x283_2x.jpg' alt={t`Healthy knee graphic`} style={{ maxWidth: '100%', height: 'auto' }} />
                    <Typography mt={2} fontStyle={'italic'} color={'primary'}>{t`Healthy knee joint`}</Typography>
                </Box>
                <Box>
                    <img src='/knee_detail_ENG2_400x283_2x.jpg' alt={t`Knee arthritis graphic`} style={{ maxWidth: '100%', height: 'auto' }} />
                    <Typography mt={2} color={'primary'} fontStyle={'italic'}>{t`Knee arthritis`}</Typography>
                </Box>
            </Box>
        </EducationPageWrapper>
    )
}
