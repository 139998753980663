import { grey } from '@mui/material/colors'

export const educationHeaderStyle = {
    color: `${grey[700]}`,
    textAlign: 'center',
    mt: 4,
    fontSize: { xs: '1.5rem', sm: '2rem' } // Responsive font size
}

export const listStyle = {
    margin: '16px 12px'
}
