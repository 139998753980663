import { t } from '@lingui/macro'
import { Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import { EducationPageWrapper } from './EducationPageWrapper'
import { educationHeaderStyle } from './EducationStyles'

export const EducationPage5: React.FC = () => {
    return (
        <EducationPageWrapper>
            <Box sx={educationHeaderStyle} m={4}>
                <Typography variant='h4' color={grey[400]}>{t`Surgical Treatment:`}</Typography>
                <Typography variant='h4'>{t`Surgery to Replace your Knee Joint`}</Typography>
            </Box>
            <Typography>{t`A knee replacement is a surgical procedure that involves removing the arthritic areas of bone and cartilage from your knee. Diseased areas of arthritis are removed and replaced with metal and plastic. This is performed at the end of the femur (called the femoral component) and the tibia (called the tibial component) (see figure).`}</Typography>
            <Typography
                my={3}
            >{t`A plastic component called a liner is placed in between the femoral and tibial components to provide smooth movement. Surgeons often remove areas of arthritis from the underside of the knee cap and replace this with a plastic component called a button.`}</Typography>
            <Typography>{t`The knee replacement aims to reduce pain and improve the function of your knee.`}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <img
                    style={{ maxWidth: '100%', height: 'auto' }}
                    src='/knee_replacement_632x306_2x.jpg'
                    alt={t`Graphic of knee replacement surgery steps`}
                />
            </Box>
        </EducationPageWrapper>
    )
}
