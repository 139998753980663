import { call, put, select } from 'redux-saga/effects'
import { SurveyService } from '@om1/joint-insights-api/codegen/client/services/SurveyService'
import { SurveyState } from '../state/survey-reducer'
import { SurveyDTO, SurveyLanguage } from '@om1/joint-insights-api'
import { JointInsightsState } from '../joint-insights-state'
import { notificationActions } from '@om1/platform-notifications'
import { t } from '@lingui/macro'

// Saga worker
export function createSubmitSurveySaga() {
    return function* () {
        const surveyState = yield select((state: JointInsightsState) => state.survey)
        const language = yield select((state: JointInsightsState) => state.language.locale)
        try {
            yield call(SurveyService.submitSurveyResultSurveyPost, { requestBody: mapStateToDto(surveyState, language) })
        } catch (error) {
            console.log(error)
            yield put(notificationActions.error(t`Error submitting survey`, { persist: true }))
        }
    }
}

const mapStateToDto = (state: SurveyState, language: SurveyLanguage): SurveyDTO => {
    return {
        patient_info: {
            age: state.patientInfo.age!,
            last_name: state.patientInfo.lastName,
            first_name: state.patientInfo.firstName,
            dob: state.patientInfo.dob,
            mrn: state.patientInfo.mrn,
            phone: state.patientInfo.phone,
            sex: state.patientInfo.sex!
        },
        survey_results: {
            quiz_answers: state.quizAnswers,
            preferences: state.preferences,
            reviews: state.reviews
        },
        language: language
    }
}
