import { t } from '@lingui/macro'
import { Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { FrameworkComponentProps } from '@om1/platform-utils'
import React from 'react'
import { Wizard } from 'react-use-wizard'
import { surveyActions } from '../../state/survey-actions'
import { createCompletedPageComponent } from './SurveyPages/CompletedPage'
import { EducationPage1, EducationPage2, EducationPage3, EducationPage4, EducationPage5, EducationPage6 } from './SurveyPages/EducationPages'
import { InfoPage } from './SurveyPages/InfoPage'
import { createLanguageSelectionPage } from './SurveyPages/LanguageSelectionPage'
import { createPatientInfoPage } from './SurveyPages/PatientInfoPage'
import { createPreferencesPage } from './SurveyPages/PreferencesPage'
import { createQuizPage } from './SurveyPages/QuizPage'
import { createResultsPage } from './SurveyPages/ResultsPage'
import { createReviewPage } from './SurveyPages/ReviewPage'
import { JointInsightsPermissions } from '../../joint-insights-state'

const LanguageSelectionPage = createLanguageSelectionPage()
const CompletedPage = createCompletedPageComponent()
const ResultsPage = createResultsPage()
const QuizPage = createQuizPage()
const PreferencesPage = createPreferencesPage()
const ReviewPage = createReviewPage()
const PatientInfoPage = createPatientInfoPage()

type PageConfig = {
    component: React.FC<{ title: string; pageType: string }>
    title: string
    pageType: 'education' | 'quiz' | 'preferences' | 'review' | 'completed' | 'results' | 'patient-info'
    isLastUserStep?: boolean
}

type SurveyPageProps = FrameworkComponentProps<{ activeStepIndex: number; permissions?: string[] }, typeof surveyActions, {}>

export const SurveyPageComponent: React.FC<SurveyPageProps> = ({ state, actions }) => {
    const { activeStepIndex, permissions } = state
    const { setStateValue } = actions
    const setActiveStepIndex = (index: number) => setStateValue('activeStepIndex', index, index)

    const pageConfigs: PageConfig[] = [
        { component: PatientInfoPage, title: t`Patient Information`, pageType: 'patient-info' },
        { component: LanguageSelectionPage, title: t`Language Selection`, pageType: 'education' },
        { component: InfoPage, title: t`How can this decision maker help you?`, pageType: 'education' },
        { component: EducationPage1, title: t`Education Page 1`, pageType: 'education' },
        { component: EducationPage2, title: t`Education Page 2`, pageType: 'education' },
        { component: EducationPage3, title: t`Education Page 3`, pageType: 'education' },
        { component: EducationPage4, title: t`Education Page 4`, pageType: 'education' },
        { component: EducationPage5, title: t`Education Page 5`, pageType: 'education' },
        { component: EducationPage6, title: t`Education Page 6`, pageType: 'education' },
        { component: (props) => <QuizPage index={0} {...props} editable={true} />, title: t`Quiz 1`, pageType: 'quiz' },
        { component: (props) => <QuizPage index={1} {...props} editable={true} />, title: t`Quiz 2`, pageType: 'quiz' },
        { component: (props) => <QuizPage index={2} {...props} editable={true} />, title: t`Quiz 3`, pageType: 'quiz' },
        { component: (props) => <QuizPage index={3} {...props} editable={true} />, title: t`Quiz 4`, pageType: 'quiz' },
        { component: (props) => <QuizPage index={4} {...props} editable={true} />, title: t`Quiz 5`, pageType: 'quiz' },
        { component: (props) => <QuizPage index={5} {...props} editable={true} />, title: t`Quiz 6`, pageType: 'quiz' },
        { component: (props) => <PreferencesPage {...props} editable={true} index={0} />, title: t`Preferences 1`, pageType: 'preferences' },
        { component: (props) => <PreferencesPage {...props} editable={true} index={1} />, title: t`Preferences 2`, pageType: 'preferences' },
        { component: (props) => <PreferencesPage {...props} editable={true} index={2} />, title: t`Preferences 3`, pageType: 'preferences' },
        { component: (props) => <ReviewPage {...props} editable={true} index={0} />, title: t`Review 1`, pageType: 'review' },
        { component: (props) => <ReviewPage {...props} editable={true} index={1} />, title: t`Review 2`, pageType: 'review' },
        {
            component: (props) => <ReviewPage {...props} editable={true} index={2} isLastUserStep={true} />,
            title: t`Review 3`,
            pageType: 'review'
        },
        { component: CompletedPage, title: t`Completed Page`, pageType: 'completed' },
        { component: ResultsPage, title: t`Results Page`, pageType: 'results' }
    ]

    return (
        <Box
            sx={{
                minHeight: '100dvh',
                backgroundColor: grey[300],
                margin: 0,
                padding: { xs: '16px', sm: '24px' },
                position: 'relative',
                overflow: 'scroll'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: '100%',
                    maxWidth: '800px', // Limit maximum width for larger screens
                    margin: '0 auto' // Center the content
                }}
            >
                {permissions?.includes(JointInsightsPermissions.POST_SURVEY) ? (
                    <Wizard onStepChange={(stepIndex: number) => setActiveStepIndex(stepIndex)} startIndex={activeStepIndex}>
                        {pageConfigs.map(({ component: PageComponent, title, pageType }, index) => (
                            <PageComponent key={index} title={title} pageType={pageType} />
                        ))}
                    </Wizard>
                ) : (
                    <Box>
                        <Typography>{t`You do not have permissions to submit the survey. Contact your administrator to get access.`}</Typography>
                    </Box>
                )}
            </Box>
        </Box>
    )
}
