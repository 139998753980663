import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { MainContainer, PageContainer } from './Layout'

export const CenterBoxWrapper = ({ children }: { children: ReactNode }) => {
    return (
        <MainContainer>
            <PageContainer sx={{ justifyContent: 'space-around', alignItems: 'center' }}>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around'
                    }}
                >
                    {children}
                </Box>
            </PageContainer>
        </MainContainer>
    )
}
