import { t } from '@lingui/macro'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { EducationPageWrapper } from './EducationPageWrapper'
import { educationHeaderStyle } from './EducationStyles'

export const EducationPage2: React.FC = () => {
    return (
        <EducationPageWrapper>
            <Typography variant='h4' sx={educationHeaderStyle} m={4}>{t`What are Your Treatment Options?`}</Typography>
            <Typography
                sx={{ textAlign: 'center', mb: 3 }}
            >{t`There is no way to reverse or cure arthritis yet. However, there are treatments that can help you feel better and do more. In most cases, it is important to try non-surgical treatments first before considering an operation. Surgery to replace the knee joint can be an option in severe cases of arthritis.`}</Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                <Box>
                    <Typography variant='h5' fontWeight={'bold'} color='primary' mb={2} textAlign='center'>{t`Non-surgical Treatments`}</Typography>
                    <img
                        src='/NonSurgTreatments_714x281_2x.jpg'
                        alt={t`Non-surgical treatments graphic`}
                        style={{
                            maxWidth: '100%',
                            height: 'auto',
                            display: 'block',
                            margin: '0 auto'
                        }}
                    />
                </Box>

                <Box>
                    <Typography
                        variant='h5'
                        fontWeight={'bold'}
                        color='primary'
                        mb={2}
                        textAlign='center'
                    >{t`Surgery to Replace your Knee Joint`}</Typography>
                    <img
                        src='/knee_implant_359x152_2x.jpg'
                        alt={t`artifical knee implant graphic`}
                        style={{
                            maxWidth: '100%',
                            height: 'auto',
                            display: 'block',
                            margin: '0 auto'
                        }}
                    />
                </Box>

                <Typography sx={{ textAlign: 'center', mt: 2 }}>{t`Each of these options has potential benefits and drawbacks.`}</Typography>
            </Box>
        </EducationPageWrapper>
    )
}
