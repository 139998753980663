import { auth0Actions, Auth0Service } from '@om1/platform-authentication'
import { call, delay, put, race, take } from 'redux-saga/effects'
import { clearDeviceFlowCodeCookie, setAccessTokenCookie, setRefreshTokenCookie } from '../cookies'
import { jointInsightsApiActions, JointInsightsApiActionTypes } from '../state'
import { handleApiError } from '../utils'

export const POLLING_INTERVAL = 5000 // Adjust the polling interval as needed

function* pollForToken(action: ReturnType<typeof jointInsightsApiActions.startAuthTokenPolling>) {
    const { deviceFlowCodeResponse, clientId } = action.payload

    while (true) {
        try {
            const data = yield call(Auth0Service.fetchToken, { deviceCode: deviceFlowCodeResponse.deviceCode, clientId })
            if (data.access_token) {
                // Handle successful authentication
                yield put(
                    jointInsightsApiActions.setPollForToken({
                        access_token: data.access_token,
                        refresh_token: data.refresh_token,
                        expires_in: data.expires_in,
                        token_type: data.token_type
                    })
                )
                setAccessTokenCookie(data.access_token, data.expires_in)
                yield put(auth0Actions.setAccessToken(data.access_token))
                if (data.refresh_token) {
                    setRefreshTokenCookie(data.refresh_token)
                    yield put(auth0Actions.setRefreshToken(data.refresh_token))
                }
                clearDeviceFlowCodeCookie()
                yield put(jointInsightsApiActions.setDeviceRegistrationAuthStatus('AUTHENTICATED'))
                yield put(jointInsightsApiActions.stopAuthTokenPolling())
            } else if (data.error === 'authorization_pending') {
                // Continue polling after a delay
                yield delay(POLLING_INTERVAL)
            } else if (data.error === 'slow_down') {
                // Continue polling after a delay
                yield delay(POLLING_INTERVAL)
            } else {
                throw new Error(data.error || 'Unknown error')
            }
        } catch (error) {
            yield handleApiError(error)
            yield put(jointInsightsApiActions.clearDeviceRegistrationAuth())
        }
    }
}

export function createPollForTokenSaga() {
    return function* () {
        while (true) {
            // Wait for polling to begin
            const action = yield take(JointInsightsApiActionTypes.START_AUTH_TOKEN_POLLING)

            // Start the race between polling and the stop action
            yield race({
                polling: call(pollForToken, action),
                cancel: take(JointInsightsApiActionTypes.STOP_AUTH_TOKEN_POLLING)
            })

            // If we're here, it means the polling was canceled because pollForToken runs an infinite loop
            yield put(jointInsightsApiActions.setPollingStatus('STOPPED'))
        }
    }
}

export type PollForTokenSaga = ReturnType<typeof createPollForTokenSaga>
