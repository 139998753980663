import { call, put } from 'redux-saga/effects'
import { DefaultService, DeviceRegistrationCodeDTO } from '../codegen/client'
import { setDeviceFlowCodeCookie } from '../cookies'
import { jointInsightsApiActions } from '../state'
import { handleApiError } from '../utils'

export function createStartDeviceRegistrationAuthSaga() {
    return function* (action: ReturnType<typeof jointInsightsApiActions.startDeviceRegistrationAuth>) {
        try {
            yield put(jointInsightsApiActions.setDeviceRegistrationAuthStatus('POLLING'))
            const deviceFlowCodeResponse: DeviceRegistrationCodeDTO = yield call(DefaultService.startOauthOauthDeviceCodePost, {
                requestBody: {
                    clientId: action.payload.clientId,
                    email: action.payload.email
                }
            })
            // Calculate the expiration time from now
            const expirationTime = Date.now() + deviceFlowCodeResponse.expiresIn * 1000

            // Set the cookie with the calculated expiration time
            const expires = new Date(expirationTime).toUTCString()
            const deviceFlowCodeResponseWithExpiresAt = { ...deviceFlowCodeResponse, expiresAt: expires, email: action.payload.email }
            setDeviceFlowCodeCookie(JSON.stringify({ ...deviceFlowCodeResponse, expiresAt: expirationTime, email: action.payload.email }), expires)
            yield put(jointInsightsApiActions.setDeviceRegistrationAuth(deviceFlowCodeResponseWithExpiresAt, expires, action.payload.email))
            yield put(jointInsightsApiActions.setPollingStatus('RUNNING'))
            yield put(
                jointInsightsApiActions.startAuthTokenPolling({
                    deviceFlowCodeResponse: deviceFlowCodeResponseWithExpiresAt,
                    expirationTime,
                    clientId: action.payload.clientId
                })
            )
        } catch (error) {
            yield handleApiError(error)
            yield put(jointInsightsApiActions.stopAuthTokenPolling())
            yield put(jointInsightsApiActions.setDeviceRegistrationAuthStatus('UNAUTHENTICATED'))
        }
    }
}

export type StartDeviceRegistrationAuthSaga = ReturnType<typeof createStartDeviceRegistrationAuthSaga>
