export interface JointInsightsApiConfigInterface {
    jointInsightsApiUrl: string
    auth0ClientId: string
    auth0AudienceDomain: string
    auth0JointInsightsScopes: string
}

export const jointInsightsApiConfig: JointInsightsApiConfigInterface = {
    jointInsightsApiUrl: process.env.REACT_APP_JOINT_INSIGHTS_API_URL as string,
    auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    auth0AudienceDomain: process.env.REACT_APP_AUTH0_AUDIENCE_DOMAIN as string,
    auth0JointInsightsScopes: process.env.REACT_APP_AUTH0_JOINT_INSIGHTS_SCOPES as string
}
