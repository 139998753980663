import { t } from '@lingui/macro'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { EducationPageWrapper } from './EducationPages/EducationPageWrapper'

export const InfoPage: React.FC = () => {
    return (
        <EducationPageWrapper>
            <Box display='flex' flexDirection={'column'} justifyContent={'center'} alignItems={'center'} margin={4}>
                <Typography variant='h4' marginBottom={4}>{t`How can this decision maker help you?`}</Typography>
                <img src='/intro_splash_436x264_2x.jpg' alt={t`decison help graphic`} width={'50%'} />
            </Box>
            <Typography>
                {t`This decision aid helps you learn about knee arthritis. It also helps you to find treatment options that match your preferences. You will also receive a personalized report of potential harms and potential benefits. You can look at this report with your doctor and share it with family and friends.`}
            </Typography>
        </EducationPageWrapper>
    )
}
