import { t } from '@lingui/macro'
import { Typography } from '@mui/material'
import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import React from 'react'
import { useWizard } from 'react-use-wizard'
import { PageWrapper } from '../../../components/PageWrapper'
import PreferencesSlider from '../../../components/PreferencesSlider'
import { surveyActions } from '../../../state/survey-actions'
import { SurveyState } from '../../../state/survey-reducer'

type PreferencesPageProps = FrameworkComponentProps<{ preferences: number[] }, typeof surveyActions, { index: number; editable?: boolean }>

export const PreferencesPage: React.FC<PreferencesPageProps> = ({ state, actions, props }) => {
    const { setStateValue } = actions
    const { preferences } = state
    const { index, editable } = props
    const { isLastStep } = useWizard()

    const setPreference = (index: number, value: number) => setStateValue('preference', index, value)

    const preferencesConfig = [
        {
            index: 0,
            helperText: t`Check your preference for different treatments by marking your choices. Each set of statements represent opposite points of view. Select the number that matches how you feel. If you don't know, you can leave it blank, but remember, there are no right or wrong answers!`,
            title: t`Coping with the Condition`,
            left: t`I would like to avoid the potential harms of surgery.`,
            right: t`I am comfortable with the potential harms of surgery.`
        },
        {
            index: 1,
            title: t`Recovery Time`,
            left: t`I am not ready to have a long recovery after an operation. This will impact my life too much.`,
            right: t`I am ready to take time off to have surgery. I will be able to follow my exercises and recovery plan for the coming year.`
        },
        {
            index: 2,
            title: t`Risks of Surgery`,
            left: t`The risks of surgery are too scary. I'm not ready to take on these risks.`,
            right: t`Risks of surgery seem worth it. The potential benefits outweigh the risks in my mind.`
        }
    ]

    const config = preferencesConfig[index]

    const PreferenceContent = () => (
        <>
            <Typography fontWeight={'bold'}>{config.title}:</Typography>
            <PreferencesSlider
                disabled={!editable}
                left={config.left}
                right={config.right}
                onChange={(value) => setPreference(index, value)}
                value={preferences[index]}
            />
        </>
    )

    if (isLastStep) {
        return <PreferenceContent />
    }

    return (
        <PageWrapper title={t`What's Important to You?`} pageType='preferences' helperText={config.helperText}>
            <PreferenceContent />
        </PageWrapper>
    )
}

export function createPreferencesPage<TState extends { survey: SurveyState }>() {
    return connectRedux(
        PreferencesPage,
        (state: TState) => ({
            ...state.survey
        }),
        { ...surveyActions }
    )
}
