import { createSubmitSurveySaga } from './submit-survey-saga'
import { SurveyActionsTypes } from '../state/survey-actions'
import { takeLatest } from 'redux-saga/effects'

export function createSurveySaga() {
    const createSubmitSurvey = createSubmitSurveySaga()

    return function* () {
        yield takeLatest(SurveyActionsTypes.SUBMIT_SURVEY, createSubmitSurvey)
    }
}
