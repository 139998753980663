export class Auth0Service {
    public static async fetchToken(body: { deviceCode: string; clientId: string }): Promise<any> {
        const result = await fetch(`${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                grant_type: 'urn:ietf:params:oauth:grant-type:device_code',
                device_code: body.deviceCode,
                client_id: body.clientId,
                scope: 'openid profile offline_access'
            })
        })

        return result.json()
    }

    public static async exchangeToken(body: { refreshToken: string; clientId: string }): Promise<any> {
        try {
            const response = await fetch(`${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    grant_type: 'refresh_token',
                    client_id: body.clientId,
                    refresh_token: body.refreshToken
                })
            })

            if (!response.ok) {
                const errorMessage = await response.text()
                throw new Error(`Failed to exchange refresh token: ${errorMessage}`)
            }

            return response.json()
        } catch (error) {
            console.error('Error exchanging refresh token:', error)
            throw error
        }
    }
}
