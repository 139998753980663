import { ActionsUnion, createAction } from '@om1/platform-utils'

export enum SurveyActionsTypes {
    SET_STATE_VALUE = '@@survey/SET_STATE_VALUE',
    SUBMIT_SURVEY = '@@survey/SUBMIT_SURVEY',
    RESET_SURVEY = '@@survey/RESET_SURVEY'
}

export type StateValueType = 'quizAnswer' | 'preference' | 'review' | 'patientInfo' | 'activeStepIndex'

export const surveyActions = {
    setStateValue: (type: StateValueType, index: string | number, value: string | number) =>
        createAction(SurveyActionsTypes.SET_STATE_VALUE, { type, index, value }),
    submitSurvey: () => createAction(SurveyActionsTypes.SUBMIT_SURVEY),
    resetSurvey: () => createAction(SurveyActionsTypes.RESET_SURVEY)
}

export type SurveyActions = ActionsUnion<typeof surveyActions>
