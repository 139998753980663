import React, { useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'

interface SelectProps {
    values: string[]
    response?: string
    onChange: (value: string) => void
    disabled?: boolean
}

const Select: React.FC<SelectProps> = ({ values, response, onChange, disabled }) => {
    const theme = useTheme()

    const [selectedValue, setSelectedValue] = useState<string | undefined>(response)

    return (
        <Box
            sx={{
                textAlign: 'center',
                borderRadius: '8px',
                marginTop: '16px'
            }}
        >
            {values.map((value, index) => {
                return (
                    <Box
                        key={index}
                        sx={{
                            marginTop: '8px',
                            padding: '12px',
                            textAlign: 'center',
                            cursor: !disabled ? 'pointer' : 'default',
                            borderRadius: '8px',
                            backgroundColor: selectedValue === value ? theme.palette.success.main : grey[300]
                        }}
                        onClick={() => {
                            if (disabled) return
                            setSelectedValue(value)
                            onChange(value)
                        }}
                    >
                        <Typography>{value}</Typography>
                    </Box>
                )
            })}
        </Box>
    )
}

export default Select
