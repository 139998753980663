import { Box, Typography, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { useMemo, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { t } from '@lingui/macro'

interface QuizProps {
    answers: string[]
    correctAnswer: string
    correctAnswerExplanation?: string
    onChange: (value: string) => void
    response?: string
    disabled?: boolean
}

const Quiz: React.FC<QuizProps> = ({ answers, correctAnswer, correctAnswerExplanation, onChange, response, disabled }) => {
    const theme = useTheme()

    const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>(response)

    const helperText = useMemo(
        () =>
            selectedAnswer ? (
                selectedAnswer === correctAnswer ? null : (
                    <>
                        <Typography mt={2} color={'success'}>
                            {t`The correct answer is` + ' ' + correctAnswer.toUpperCase()}
                        </Typography>
                        {correctAnswerExplanation && (
                            <Typography mt={2} color={'success'} align={'center'}>
                                {correctAnswerExplanation}
                            </Typography>
                        )}
                    </>
                )
            ) : null,
        [selectedAnswer, correctAnswer, correctAnswerExplanation]
    )

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '100%',
                borderRadius: '8px',
                marginTop: '10px'
            }}
        >
            {answers.map((answer, index) => {
                let backgroundColor, color, icon
                if (selectedAnswer === answer) {
                    backgroundColor = answer === correctAnswer ? theme.palette.success.main : theme.palette.error.main
                } else {
                    backgroundColor = grey[300]
                }
                if (selectedAnswer) {
                    if (answer === correctAnswer) {
                        color = selectedAnswer === correctAnswer ? 'white' : theme.palette.success.main
                        if (selectedAnswer === answer) {
                            icon = <CheckIcon />
                        } else if (answer === correctAnswer) {
                            icon = <CheckIcon />
                        }
                    } else {
                        color = selectedAnswer !== answer ? 'black' : 'white'
                        icon = selectedAnswer === answer ? <ClearIcon /> : null
                    }
                }
                return (
                    <Box
                        key={index}
                        sx={{
                            width: '100%',
                            marginTop: '8px',
                            padding: '12px',
                            textAlign: 'center',
                            cursor: !selectedAnswer && !disabled ? 'pointer' : 'default',
                            backgroundColor,
                            borderRadius: '8px',
                            color
                        }}
                        onClick={() => {
                            if (selectedAnswer || disabled) return
                            setSelectedAnswer(answer)
                            onChange(answer)
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px'
                            }}
                        >
                            <Typography>{answer}</Typography>
                            {icon}
                        </Box>
                    </Box>
                )
            })}
            {helperText}
        </Box>
    )
}

export default Quiz
