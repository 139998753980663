/* eslint-disable */
import { Box } from '@mui/material'
import { DeviceRegistrationAuthStatusType, jointInsightsApiActions, JointInsightsApiState } from '@om1/joint-insights-api'
import { auth0Actions, Auth0State } from '@om1/platform-authentication'
import { PlatformConfigState } from '@om1/platform-config/state'
import { notificationActions } from '@om1/platform-notifications'
import { Routes, toPath } from '@om1/platform-routing'
import { MainContainer, PageContainer } from '@om1/platform-ui-kit/src/components/Layout'
import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { JointInsightsState } from '../joint-insights-state'
import { languageActions } from '../shared/providers/internationalization/language-actions'
import { LanguageState } from '../shared/providers/internationalization/language-state'
import { createAuthenticationHandlerComponent } from './AuthenticationHandler'

const AuthenticationHandler = createAuthenticationHandlerComponent<JointInsightsState>()

type LandingPageComponentProps = FrameworkComponentProps<
    { authStatus: DeviceRegistrationAuthStatusType } & PlatformConfigState & Auth0State & LanguageState,
    typeof notificationActions & typeof auth0Actions & typeof jointInsightsApiActions & typeof languageActions,
    {}
>

const LandingPageComponent: React.FunctionComponent<LandingPageComponentProps> = ({ actions, state }) => {
    return (
        <MainContainer>
            <PageContainer>
                <Box
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <Switch>
                        <Route key={'home'} path={'/'}>
                            <AuthenticationHandler />
                        </Route>
                        <Redirect key={'notFoundRedirect'} from={'*'} to={toPath(Routes.NOTFOUND)} />
                    </Switch>
                </Box>
            </PageContainer>
        </MainContainer>
    )
}

export function createLandingPageComponent<
    TState extends { jointInsightsApi: JointInsightsApiState; platformConfig: PlatformConfigState; auth0: Auth0State; language: LanguageState }
>() {
    return connectRedux(
        LandingPageComponent,
        (state: TState) => {
            return { ...state.platformConfig, ...state.auth0, ...state.language, authStatus: state.jointInsightsApi.authStatus }
        },
        { ...notificationActions, ...auth0Actions, ...jointInsightsApiActions, ...languageActions }
    )
}

export type LandingPage = ReturnType<typeof createLandingPageComponent>
