import { t } from '@lingui/macro'
import { Box, Button, Typography } from '@mui/material'
import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import React, { useEffect } from 'react'
import { useWizard } from 'react-use-wizard'
import { headerStyle, pageStyle } from '../../../components/PageStyles'
import { createPatientInfoHeaderComponent } from '../../../components/PatientInfoHeader'
import { surveyActions } from '../../../state/survey-actions'

const PatientInfoHeader = createPatientInfoHeaderComponent()

type CompletedPageComponentProps = FrameworkComponentProps<{}, typeof surveyActions, {}>

export const CompletedPageComponent: React.FC<CompletedPageComponentProps> = ({ actions }) => {
    const { nextStep } = useWizard()

    useEffect(() => {
        actions.submitSurvey()
    }, [actions])

    return (
        <Box sx={pageStyle}>
            <Box sx={headerStyle} style={{ position: 'relative' }}>
                <Typography
                    variant='h5'
                    alignSelf={'center'}
                    textAlign={'center'}
                >{t`Thank you for completing all the survey questions!`}</Typography>
                <PatientInfoHeader style={{ position: 'absolute', top: 5, right: 10 }} />
            </Box>
            <Box textAlign={'center'}>
                <img src='/survey-complete-illustration-550px.png' alt={t`Completed survey graphic`} style={{ maxWidth: '100%', height: 'auto' }} />
            </Box>
            <Box p={2}>
                <Button variant='contained' color='primary' fullWidth onClick={nextStep}>{t`See your results`}</Button>
            </Box>
        </Box>
    )
}

export function createCompletedPageComponent<TState extends {}>() {
    return connectRedux(CompletedPageComponent, (state: TState) => ({}), { ...surveyActions })
}
