import { Trans } from '@lingui/macro'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { DeviceRegistrationAuthStatusType, jointInsightsApiActions, JointInsightsApiState } from '@om1/joint-insights-api'
import { CenterBoxWrapper } from '@om1/platform-ui-kit/src/components/CenterBoxWrapper'
import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import React from 'react'

type PollingPageProps = FrameworkComponentProps<
    { authStatus: DeviceRegistrationAuthStatusType; email: string | undefined },
    Pick<typeof jointInsightsApiActions, 'clearDeviceRegistrationAuth'>,
    {}
>

const PollingPageComponent: React.FC<PollingPageProps> = ({ state, actions }) => {
    const { authStatus, email } = state

    if (authStatus === 'AUTHENTICATED') {
        return <></>
    }

    const renderPollingMessage = () => {
        if (authStatus === 'CHECKING_AUTH') {
            return (
                <Typography variant='body1'>
                    <Trans>Checking for session in progress...</Trans>
                </Typography>
            )
        }

        if (authStatus === 'POLLING') {
            return (
                <>
                    <Typography variant='h6'>
                        <Trans>Your device authorization request has been sent.</Trans>
                    </Typography>

                    <Box color='grey.500' component='span'>
                        <CircularProgress color='inherit' size='5em' />
                    </Box>
                    <Typography variant='body1' sx={{ marginBottom: '20px', maxWidth: '400px' }}>
                        <Trans>Please check your email</Trans>
                        {` (${email}) `}
                        <Trans>
                            for further instructions on how to complete the authorization process. This page will automatically update when the
                            process is complete.
                        </Trans>
                    </Typography>
                    <Button variant='contained' type='reset' color='error' onClick={() => actions.clearDeviceRegistrationAuth()}>
                        <Trans>Cancel Registration</Trans>
                    </Button>
                </>
            )
        }
    }

    return (
        <CenterBoxWrapper>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    rowGap: '30px',
                    padding: '100px 100px',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
                    backgroundColor: 'white'
                }}
            >
                {renderPollingMessage()}
            </Box>
        </CenterBoxWrapper>
    )
}

export function createPollingPageComponent<TState extends { jointInsightsApi: JointInsightsApiState }>() {
    return connectRedux(
        PollingPageComponent,
        (state: TState) => ({
            authStatus: state.jointInsightsApi.authStatus,
            email: state.jointInsightsApi.deviceCodeResponse?.email
        }),
        {
            clearDeviceRegistrationAuth: jointInsightsApiActions.clearDeviceRegistrationAuth
        }
    )
}

export type PollingPage = ReturnType<typeof createPollingPageComponent>
