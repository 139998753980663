import { notificationActions } from '@om1/platform-notifications'
import { put } from 'redux-saga/effects'
import { ApiError } from '../codegen/client'
import { jointInsightsApiActions } from '../state'

export function createGetJointInsightsVersionSaga() {
    return function* (_action: ReturnType<typeof jointInsightsApiActions.getJointInsightsVersion>) {
        try {
            // const response: VersionDTO = yield call(CoreService.versionVersionGet)
            // yield put(jointInsightsApiActions.setJointInsightsVersion(response.version, response.alembicVersion, response.analyticsDatabase))
            yield put(jointInsightsApiActions.setJointInsightsVersion('test', 'test', 'test'))
        } catch (error: unknown) {
            const apiError: ApiError = error as unknown as ApiError
            yield put(notificationActions.error(apiError.message))
        }
    }
}

export type GetJointInsightsVersionSaga = ReturnType<typeof createGetJointInsightsVersionSaga>
