import { t } from '@lingui/macro'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, Button } from '@mui/material'
import React from 'react'
import { useWizard } from 'react-use-wizard'

type ControlButtonsComponentProps = {
    isLastUserStep?: boolean
    handleSubmit?: () => void
    canGoToNextStep: boolean
}

export const ControlButtons: React.FC<ControlButtonsComponentProps> = ({ isLastUserStep, handleSubmit, canGoToNextStep }) => {
    const { previousStep, nextStep, handleStep, isFirstStep, isLastStep } = useWizard()

    if (handleSubmit) {
        handleStep(handleSubmit)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: !isFirstStep ? 'space-between' : 'flex-end',
                padding: '10px 20px',
                width: '100%',
                marginBottom: '20px',
                marginTop: '10px'
            }}
        >
            {!isFirstStep && (
                <Button onClick={previousStep}>
                    <ArrowBackIosIcon />
                    {t`Previous`}
                </Button>
            )}
            {!isLastStep && (
                <Button disabled={!canGoToNextStep} onClick={nextStep}>
                    {isLastUserStep ? t`Finish` : t`Next`}
                    <ArrowForwardIosIcon />
                </Button>
            )}
        </Box>
    )
}
