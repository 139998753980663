import { t } from '@lingui/macro'
import { Box } from '@mui/material'
import React, { ReactNode } from 'react'
import { Logo } from '../../../../components/PageStyles'
import { PageWrapper } from '../../../../components/PageWrapper'
import OM1Logo from '../../../../shared/assets/OM1_logo_1953x934.png'

export const EducationPageWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <PageWrapper title={t`Shared Decision Aid for: Knee Arthritis`} pageType='education'>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Logo src={OM1Logo} alt={t`OM1 Logo`} width={'64px'} />
            </Box>
            {children}
        </PageWrapper>
    )
}
