import { SurveyActions, SurveyActionsTypes } from './survey-actions'

export enum Sex {
    Male = 'MALE',
    Female = 'FEMALE'
}

export interface PatientInfo {
    age?: number
    lastName: string
    firstName: string
    dob: string
    mrn: string
    phone: string
    sex?: Sex
}

export interface SurveyState {
    quizAnswers: string[]
    preferences: number[]
    reviews: string[]
    patientInfo: PatientInfo
    activeStepIndex: number
}

export const initialSurveyState: SurveyState = {
    quizAnswers: ['', '', '', '', '', ''],
    preferences: [5, 5, 5],
    reviews: ['', '', ''],
    activeStepIndex: 0,
    patientInfo: {
        age: undefined,
        lastName: '',
        firstName: '',
        dob: '',
        mrn: '',
        phone: '',
        sex: undefined
    }
}

export function surveyReducer(previousState: SurveyState | undefined, action: SurveyActions): SurveyState {
    const state = previousState || initialSurveyState

    switch (action.type) {
        case SurveyActionsTypes.SET_STATE_VALUE: {
            const { type, index, value } = action.payload
            switch (type) {
                case 'quizAnswer':
                    return {
                        ...state,
                        quizAnswers: state.quizAnswers.map((answer, i) => (i === index ? (value as string) : answer))
                    }
                case 'preference':
                    return {
                        ...state,
                        preferences: state.preferences.map((pref, i) => (i === index ? (value as number) : pref))
                    }
                case 'review':
                    return {
                        ...state,
                        reviews: state.reviews.map((review, i) => (i === index ? (value as string) : review))
                    }
                case 'patientInfo':
                    return {
                        ...state,
                        patientInfo: {
                            ...state.patientInfo,
                            [index]: value
                        }
                    }
                case 'activeStepIndex':
                    return {
                        ...state,
                        activeStepIndex: value as number
                    }
                default:
                    return state
            }
        }
        case SurveyActionsTypes.RESET_SURVEY:
            return { ...initialSurveyState }
        default:
            return state
    }
}
